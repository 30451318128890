@font-face {
  font-family: 'icons';
  src: url('../../assets/fonts/icons/icomoon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icons', serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$style-icons: (
    icon-bullet-point: '\e900',
    icon-close: '\e901',
    icon-menu: '\e902',
    icon-arrow: '\e903',
    icon-back-to-top: '\e904',
    icon-logo: '\e905',
);

@each $icon-name,
$icon-code in $style-icons {
  .#{$icon-name}::before {
    content: '#{$icon-code}';
  }
}
